import axios from "axios";
import React, { useEffect, useState } from "react";
import https from "https";
import { useOperationMethod } from "react-openapi-client";
import { Parameters } from "openapi-client-axios";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { ServiceView } from "../Types/api";
import { ServicesService } from "../Services/ServicesService";

export default function VerifyEmail() {
  const { verificationToken } = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("service");
  const [verifyEmail, { loading, data, error }] = useOperationMethod(
    "UserverifyUser{token}"
  );

  const [service, setService] = useState<ServiceView>();
  const [logoUrl, setLogoUrl] = useState<string | null>();

  const _servicesService = new ServicesService(
    process.env.REACT_APP_API_BASEURL as string
  );

  const getService = async (serviceId: string) => {
    const thisService = await _servicesService.getServiceById(
      serviceId as string
    );
    setService(thisService);
    console.log({ thisService });
    return thisService;
  };

  const getLogoUrl = (service: ServiceView) => {
    const logo = ServicesService.generateServiceLogoUrl(
      service.logoReference as string
    );
    console.log({ logo });
    setLogoUrl(logo);
  };

  useEffect(() => {
    console.log({ id });

    const getData = async () => {
      const service = await getService(id as string);
      const params: Parameters = {
        token: verificationToken as string,
      };
      var result = await verifyEmail(params);

      getLogoUrl(service);
    };
    getData();
  }, []);

  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div className="nk-main ">
        {/* wrap @s */}
        <div className="nk-wrap nk-wrap-nosidebar">
          {/* content @s */}
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body">
              <div className="brand-logo pb-5">
                <a href="html/index.html" className="logo-link">
                  <img
                    className="logo-light logo-img logo-img-lg"
                    src="./images/logo.png"
                    srcSet="./images/logo2x.png 2x"
                    alt="logo"
                  />
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    srcSet={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    alt="logo-dark"
                  />
                </a>
              </div>
              <div className="nk-block-head">
                <div className="nk-block-head-content">
                  <h4 className="nk-block-title">
                    Thank you , Your account has been successfully verified.
                  </h4>
                  <div className="nk-block-des text-success">
                    {service?.loginUrl ? (
                      <Link to={`${service?.loginUrl}`}>
                        You can now sign in with your new password
                      </Link>
                    ) : (
                      <Link to={`/login/${id}`}>
                        You can now sign in with your new password
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* wrap @e */}
        </div>
        {/* content @e */}
      </div>
      {/* main @e */}
    </div>
  );
}
