import React, { useCallback, useEffect, useState } from "react";
import { useRoutes, useSearchParams } from "react-router-dom";
import { MainContext } from "../../Contexts/MainContext";
import Routes from "../../Routes/Routes";
import { UserView } from "../../Types/api";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme";
import { OpenAPIProvider } from "react-openapi-client";
import schema from "../../Types/api-schema.json";
import {
  ThemeProvider,
  createTheme,
  makeStyles,
} from "@material-ui/core/styles";
import { ToastProvider } from "react-toast-notifications";

const muitheme = createTheme();

function App() {
  const [data, setData] = useState({});
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const setUser = useCallback(
    (user?: UserView) => {
      localStorage.setItem("user", JSON.stringify(user));
      setData({ ...data, user: user });
    },
    [data, setData]
  );

  const headers: HeadersInit = {
    cor: "no-cors",
    Authorization: `Bearer ${
      localStorage.getItem("token") ? localStorage.getItem("token") : ""
    }`,
  };

  useEffect(() => {
    localStorage.getItem("user")
      ? setUser(JSON.parse(localStorage.getItem("user") || "{}"))
      : localStorage.setItem("", "");
      localStorage.setItem("token", token as string);
  }, [token]);

  let elements = useRoutes(Routes);
  return (
    <MainContext.Provider value={{ data, setUser }}>
      <OpenAPIProvider
        definition={process.env.REACT_APP_API_DEFINITION as string}
        axiosConfigDefaults={{
          withCredentials: true,
          headers,
          baseURL: process.env.REACT_APP_API_BASEURL,
        }}
      >
        <ThemeProvider theme={muitheme}>
          {/* @ts-ignore */}
          <ToastProvider>
            <ChakraProvider theme={theme}>{elements}</ChakraProvider>
          </ToastProvider>
        </ThemeProvider>
      </OpenAPIProvider>
    </MainContext.Provider>
  );
}

export default App;
