import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useOperationMethod } from "react-openapi-client";
import { useToasts } from "react-toast-notifications";
import { Parameters } from "openapi-client-axios";
import * as yup from "yup";
import { InitiateResetModel, ServiceView } from "../Types/api";
import { ServicesService } from "../Services/ServicesService";
import { PrimaryInput } from "../Components/CustomInputs/PrimaryInput";
import PrimaryButton from "../Components/CustomInputs/PrimaryButton";
import { Link, useParams } from "react-router-dom";

const schema = yup.object().shape({
  email: yup.string().required("Email is required"),
});

export default function InitiateResetPassword() {
  const { serviceId: id } = useParams();
  const [service, setService] = useState<ServiceView>();
  const [logoUrl, setLogoUrl] = useState<string | null>();
  const [done, setDone] = useState<boolean>(false);

  const _servicesService = new ServicesService(
    process.env.REACT_APP_API_BASEURL as string
  );

  const getService = async (serviceId: string) => {
    const thisService = await _servicesService.getServiceById(
      serviceId as string
    );
    setService(thisService);
    console.log({ thisService });
    return thisService;
  };

  const getLogoUrl = (service: ServiceView) => {
    const logo = ServicesService.generateServiceLogoUrl(
      service.logoReference as string
    );
    console.log({ logo });
    setLogoUrl(logo);
  };

  useEffect(() => {
    console.log({ id });

    const getData = async () => {
      const service = await getService(id as string);
      getLogoUrl(service);
    };
    getData();
  }, []);
  const { addToast } = useToasts();

  const [initiatePasswordReset, { loading, response, error }] =
    useOperationMethod("Userresetinitiate");

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<InitiateResetModel>({
    defaultValues: { email: "" },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: InitiateResetModel) => {
    console.log(data);
    data.serviceId = service?.id;
    try {
      const result = await initiatePasswordReset(undefined, data);
      console.log({ loading, response, error });

      console.log({ result });
      if (result.data.status) {
        addToast("Request Successful", { appearance: "success" });
        setDone(true);
        // router.push(`/login/${service?.id}`);
        return;
      }
      addToast(result.data.message, { appearance: "error", autoDismiss: true });
      return;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div className="nk-main ">
        {/* wrap @s */}
        <div className="nk-wrap nk-wrap-nosidebar">
          {/* content @s */}
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  <img
                    className="logo-light logo-img logo-img-lg"
                    src={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    srcSet={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    alt="logo"
                  />
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    srcSet={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    alt="logo-dark"
                  />
                </a>
              </div>
              {done ? (
                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h4 className="nk-block-title">
                      Your request has been successfully processed, Please check
                      your email for further information.
                    </h4>
                    <div className="nk-block-des text-success">
                      <Link to={`/login/${service?.id}`}>Go to Login</Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card card-bordered">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h5 className="nk-block-title">Reset password</h5>
                        <div className="nk-block-des">
                          <p>Put in your email address below</p>
                        </div>
                      </div>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <PrimaryInput<InitiateResetModel>
                        name={"email"}
                        label={"Email"}
                        placeholder={"Email"}
                        error={errors.email}
                        register={register}
                        defaultValue={""}
                        type="email"
                        data-testid="create-user-modal-first-name"
                      />
                      <PrimaryButton
                        isLoading={loading}
                        text="Submit"
                        type="submit"
                      />
                    </form>
                    <div className="form-note-s2 text-center pt-4">
                      <Link to={`/login/${service?.id}`}>
                        <a>
                          <strong>Return to login</strong>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* wrap @e */}
        </div>
        {/* content @e */}
      </div>
      {/* main @e */}
    </div>
  );
}
