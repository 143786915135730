import React from "react";
import CompleteResetPassword from "../Pages/CompleteResetPassword";
import InitiateResetPassword from "../Pages/InitiateResetPassword";
import Login from "../Pages/Login";
import Register from "../Pages/register";
import UpdatePassword from "../Pages/UpdatePassword";
import VerifyNewUser from "../Pages/VerifyNewUser";
import { IRoutes } from "../Types/App/Types";

const Routes: IRoutes[] = [
  {
    path: "/login/:serviceId",
    key: "LOGIN",
    element: <Login />,
  },
  {
    path: "/register/:serviceId",
    key: "REGISTER",
    element: <Register />,
  },
  {
    path: "/register/verify/:verificationToken",
    key: "REGISTER",
    element: <VerifyNewUser />,
  },
  {
    path: "/password/change/:serviceId",
    key: "PASSWORD_CHANGE",
    element: <UpdatePassword />,
  },
  {
    path: "/password/reset/initiate/:serviceId",
    key: "PASSWORD_RESET_INITIATE",
    element: <InitiateResetPassword />,
  },
  {
    path: "/password/reset/:resetToken",
    key: "PASSWORD_RESET",
    element: <CompleteResetPassword />,
  },
];

export default Routes;

// function RoutesWithSubRoutes(route: IRoutes) {
//   return (
//     <Route
//       path={route.path}
//       render={(props:any) => <route.component {...props} routes={route.routes} />}
//     />
//   );
// }

// export function RenderRoutes({ routes }: { routes: IRoutes[] }) {
//   return (
//     <Routes>
//       {routes.map((route, i) => {
//         return <RoutesWithSubRoutes {...route} />;
//       })}
//       <Route element={<h1>No Content Here!</h1>} />
//     </Routes>
//   );
// }
