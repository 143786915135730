import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useOperationMethod } from "react-openapi-client";
import { useToasts } from "react-toast-notifications";
import { ServicesService } from "../Services/ServicesService";
import { LoginModel, ServiceView } from "../Types/api";
import { PrimaryInput } from "../Components/CustomInputs/PrimaryInput";
import PrimaryButton from "../Components/CustomInputs/PrimaryButton";
import { useParams, useSearchParams } from "react-router-dom";

const schema = yup.object().shape({
  email: yup.string().required("Email is required"),
  password: yup.string().required("Password is required"),
});
export const Login = () => {
  const { addToast } = useToasts();
  const { serviceId } = useParams();
  const [searchParams] = useSearchParams();
  const redirect_url = searchParams.get("redirect_url");
  const _servicesService = new ServicesService(
    process.env.REACT_APP_API_BASEURL as string
  );

  const [service, setService] = useState<ServiceView>();
  const [logoUrl, setLogoUrl] = useState<string | null>();

  const getService = async (serviceId: string) => {
    const thisService = await _servicesService.getServiceById(
      serviceId as string
    );
    setService(thisService);
    console.log({ thisService });
    return thisService;
  };

  const getLogoUrl = (service: ServiceView) => {
    const logo = ServicesService.generateServiceLogoUrl(
      service.logoReference as string
    );
    console.log({ logo });
    setLogoUrl(logo);
  };

  useEffect(() => {
    console.log({ serviceId });

    const getData = async () => {
      const service = await getService(serviceId as string);
      getLogoUrl(service);
    };
    getData();
  }, []);

  const [logUserIn, { loading, response, error }] = useOperationMethod(
    "Userlogin{serviceId}"
  );

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<LoginModel>({
    defaultValues: { serviceId: serviceId as string },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: LoginModel) => {
    console.log(data);
    const result = await logUserIn(undefined, data);
    console.log({ result });
    if (result.data.status) {
      addToast("Login Successful", { appearance: "success" });
      window.location.href = `${redirect_url}/?token=${result.data.data.token}`;
      return;
    }
    addToast(result.data.message, { appearance: "error", autoDismiss: true });
    return;
  };

  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div className="nk-main ">
        {/* wrap @s */}
        <div className="nk-wrap nk-wrap-nosidebar">
          {/* content @s */}
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  <img
                    className="logo-light logo-img logo-img-lg"
                    src={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    srcSet={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    alt="logo"
                  />
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    srcSet={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    alt="logo-dark"
                  />
                </a>
              </div>
              <div className="card card-bordered">
                <div className="card-inner card-inner-lg">
                  <div className="nk-block-head">
                    <div className="nk-block-head-content">
                      <h4 className="nk-block-title">Sign-In</h4>
                      <div className="nk-block-des">
                        {/* <p>
                          Access the CryptoLite panel using your email and
                          passcode.
                        </p> */}
                      </div>
                    </div>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <PrimaryInput<LoginModel>
                      name={"email"}
                      label={"Email"}
                      placeholder={"EmailAddress"}
                      error={errors.email}
                      register={register}
                      defaultValue={""}
                      data-testid="create-user-modal-first-name"
                    />
                    <PrimaryInput<LoginModel>
                      name={"password"}
                      label={"Password"}
                      placeholder={"Password"}
                      error={errors.email}
                      register={register}
                      defaultValue={""}
                      type="password"
                      data-testid="create-user-modal-first-name"
                    />
                    <PrimaryButton
                      isLoading={loading}
                      text="Login"
                      type="submit"
                    />
                  </form>
                  <div className="form-note-s2 text-center pt-4">
                    <a href={`/register/${serviceId}`}>
                      Create an account
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* wrap @e */}
        </div>
        {/* content @e */}
      </div>
      {/* main @e */}
    </div>
  );
};

export default Login;
