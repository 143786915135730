import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useOperationMethod } from "react-openapi-client";
import { Link, useParams, useSearchParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import * as yup from "yup";
import PrimaryButton from "../Components/CustomInputs/PrimaryButton";
import { PrimaryInput } from "../Components/CustomInputs/PrimaryInput";
import { ServicesService } from "../Services/ServicesService";
import { PasswordReset, ServiceView } from "../Types/api";

const schema = yup.object().shape({
  newPassword: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
});

export default function CompleteResetPassword() {
  const { resetToken } = useParams();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("service");
  const redirectUrl = searchParams.get("redirectUrl");
  const { addToast } = useToasts();
  const [changePassword, { loading, response, error }] =
    useOperationMethod("Userresetcomplete");

  const [service, setService] = useState<ServiceView>();
  const [logoUrl, setLogoUrl] = useState<string | null>();
  const [done, setDone] = useState<boolean>(false);

  const _servicesService = new ServicesService(
    process.env.REACT_APP_API_BASEURL as string
  );

  const getService = async (serviceId: string) => {
    const thisService = await _servicesService.getServiceById(
      serviceId as string
    );
    setService(thisService);
    console.log({ thisService });
    return thisService;
  };

  const getLogoUrl = (service: ServiceView) => {
    const logo = ServicesService.generateServiceLogoUrl(
      service.logoReference as string
    );
    console.log({ logo });
    setLogoUrl(logo);
  };

  useEffect(() => {
    const getData = async () => {
      const service = await getService(id as string);
      getLogoUrl(service);
    };
    getData();
  }, []);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<PasswordReset>({
    defaultValues: { code: resetToken },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: PasswordReset) => {
    data.code = resetToken;
    console.log(data);
    const result = await changePassword(undefined, data);
    console.log({ result });
    if (result.data.status) {
      addToast("Password Reset Successful", { appearance: "success" });
      setValue("newPassword", "");
      setDone(true);
      if (redirectUrl) {
        window.location.href = redirectUrl;
      }
      //   router.push(`/login/${serviceId}`);
      return;
    }
    addToast(result.data.message, { appearance: "error", autoDismiss: true });
    return;
  };

  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div className="nk-main ">
        {/* wrap @s */}
        <div className="nk-wrap nk-wrap-nosidebar">
          {/* content @s */}
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body  wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  <img
                    className="logo-light logo-img logo-img-lg"
                    src={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    srcSet={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                  />
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    srcSet={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    alt="logo-dark"
                  />
                </a>
              </div>
              {done ? (
                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h4 className="nk-block-title">
                      Your password reset is completed, please proceed to login.
                    </h4>
                    <div className="nk-block-des text-success">
                      <Link to={`/login/${service?.id}`}>Go to Login</Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card card-bordered">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        <h5 className="nk-block-title">Change password</h5>
                        <div className="nk-block-des">
                          <p>Put in your new password below</p>
                        </div>
                      </div>
                    </div>
                    <form
                      onSubmit={handleSubmit(onSubmit, (error) =>
                        console.log(error)
                      )}
                    >
                      <PrimaryInput<PasswordReset>
                        name={"newPassword"}
                        label={"Password"}
                        placeholder={"Password"}
                        error={errors.newPassword}
                        register={register}
                        defaultValue={""}
                        type="password"
                        data-testid="create-user-modal-first-name"
                      />
                      <PrimaryButton
                        isLoading={loading}
                        text="Submit"
                        type="submit"
                      />
                    </form>
                    {/* <div className="form-note-s2 text-center pt-4">
                    <Link href={`/login/${serviceId}`}>
                      <a>
                        <strong>Return to login</strong>
                      </a>
                    </Link>
                  </div> */}
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* wrap @e */}
        </div>
        {/* content @e */}
      </div>
      {/* main @e */}
    </div>
  );
}
