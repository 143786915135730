import axios from "axios";
import https from "https";
import { ServiceView, ServiceViewStandardResponse } from "../Types/api";

const agent = new https.Agent({
  rejectUnauthorized: false,
});
export class ServicesService {
  baseURL: string;
  constructor(baseURL: string) {
    this.baseURL = baseURL;
  }

  public getServiceById = async (id: string): Promise<ServiceView> => {
    console.log(this.baseURL);

    try {
      const result: ServiceViewStandardResponse = (await (
        await axios.get(`${this.baseURL}api/services/${id}`, {
          httpsAgent: agent,
        })
      ).data) as unknown as ServiceViewStandardResponse;
      return new Promise<ServiceView>((resolve, reject) => {
        resolve(result.data as ServiceView);
      });
    } catch (error) {
      return new Promise<ServiceView>((resolve, reject) => {
        reject(error);
      });
    }
  };
  public static generateServiceLogoUrl = (logoReference: string) => {
    return logoReference
      ? `${process.env.REACT_APP_FILE_SERVICE_BASEURL}/api/file/${logoReference}/${process.env.REACT_APP_FILE_SERVICE_kEY}`
      : null;
  };
}
