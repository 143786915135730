import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useOperationMethod } from "react-openapi-client";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useToasts } from "react-toast-notifications";
import { RegisterModel, ServiceView } from "../Types/api";
import { ServicesService } from "../Services/ServicesService";
import { UserTypes } from "../Types/App/Types";
import { PrimaryInput } from "../Components/CustomInputs/PrimaryInput";
import PrimaryButton from "../Components/CustomInputs/PrimaryButton";

const schema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup.string().required("Email is required"),
  password: yup
    .string()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  // serviceId: yup.string().required('Service ID is required'),
  userType: yup.string().required("User type is required"),
});

export const Register = (): JSX.Element => {
  let navigate = useNavigate();
  const { serviceId: currentServiceId } = useParams();
  const { addToast } = useToasts();

  const [service, setService] = useState<ServiceView>();
  const [logoUrl, setLogoUrl] = useState<string | null>();
  const [done, setDone] = useState<boolean>(false);

  const _servicesService = new ServicesService(
    process.env.REACT_APP_API_BASEURL as string
  );

  const getService = async (serviceId: string) => {
    const thisService = await _servicesService.getServiceById(
      serviceId as string
    );
    setService(thisService);
    console.log({ thisService });
    return thisService;
  };

  const getLogoUrl = (service: ServiceView) => {
    const logo = ServicesService.generateServiceLogoUrl(
      service.logoReference as string
    );
    console.log({ logo });
    setLogoUrl(logo);
  };

  useEffect(() => {
    console.log({ currentServiceId });

    const getData = async () => {
      const service = await getService(currentServiceId as string);
      getLogoUrl(service);
    };
    getData();
  }, []);

  const [createUser, { loading, response, error }] = useOperationMethod(
    "Userregister{serviceId}"
  );
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm<RegisterModel>({
    defaultValues: {
      serviceId: currentServiceId as string,
      userType: UserTypes.SERVICE_USER,
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: RegisterModel) => {
    data.serviceId = currentServiceId as string;
    console.log(data);
    const result = await createUser(undefined, data);
    console.log({ result });
    if (result.data.status) {
      addToast("Registration Successful", { appearance: "success" });
      setDone(true);
      // navigate(`/login/${currentServiceId}`);
      return;
    }
    addToast(result.data.message, { appearance: "error", autoDismiss: true });
    return;
  };

  return (
    <div className="nk-app-root">
      {/* main @s */}
      <div className="nk-main ">
        {/* wrap @s */}
        <div className="nk-wrap nk-wrap-nosidebar">
          {/* content @s */}
          <div className="nk-content ">
            <div className="nk-block nk-block-middle nk-auth-body wide-xs">
              <div className="brand-logo pb-4 text-center">
                <a href="html/index.html" className="logo-link">
                  <img
                    className="logo-light logo-img logo-img-lg"
                    src={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    srcSet={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    alt="logo"
                  />
                  <img
                    className="logo-dark logo-img logo-img-lg"
                    src={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    srcSet={
                      logoUrl ??
                      "https://www.suretree.com/img/Suretree_Logo_Lockup_Centred_PMS2728U.png"
                    }
                    alt="logo-dark"
                  />
                </a>
              </div>
              {done ? (
                <div className="nk-block-head">
                  <div className="nk-block-head-content">
                    <h4 className="nk-block-title">
                      Your registration is completed, please check your email
                      for further information.
                    </h4>
                    <div className="nk-block-des text-success">
                      <Link to={`/login/${service?.id}`}>Go to Login</Link>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="card card-bordered">
                  <div className="card-inner card-inner-lg">
                    <div className="nk-block-head">
                      <div className="nk-block-head-content">
                        {/* <h4 className="nk-block-title">Register</h4> */}
                        <div className="nk-block-des">
                          <p>Create New Account</p>
                        </div>
                      </div>
                    </div>
                    <form
                      onSubmit={handleSubmit(onSubmit, (error) =>
                        console.log({ error })
                      )}
                    >
                      <PrimaryInput<RegisterModel>
                        name={"firstName"}
                        label={"First Name"}
                        placeholder={"First name"}
                        error={errors.firstName}
                        register={register}
                        defaultValue={""}
                        data-testid="create-user-modal-first-name"
                      />
                      <PrimaryInput<RegisterModel>
                        name={"lastName"}
                        label={"Last Name"}
                        placeholder={"Last name"}
                        error={errors.lastName}
                        register={register}
                        defaultValue={""}
                        data-testid="create-user-modal-first-name"
                      />
                      <PrimaryInput<RegisterModel>
                        name={"email"}
                        label={"Email"}
                        placeholder={"Email Address"}
                        error={errors.email}
                        register={register}
                        defaultValue={""}
                        data-testid="create-user-modal-first-name"
                      />
                      <PrimaryInput<RegisterModel>
                        name={"password"}
                        label={"Password"}
                        placeholder={"Password here"}
                        error={errors.password}
                        register={register}
                        defaultValue={""}
                        type="password"
                        data-testid="create-user-modal-first-name"
                      />
                      <PrimaryButton
                        isLoading={loading}
                        text="Register"
                        type="submit"
                      />
                    </form>
                    <div className="form-note-s2 text-center pt-4">
                      {" "}
                      Already have an account?{" "}
                      <Link to={`/login/${currentServiceId}`}>
                        <a>
                          <strong>Sign in instead</strong>
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* wrap @e */}
        </div>
        {/* content @e */}
      </div>
      {/* main @e */}
    </div>
  );
};

export default Register;
